import { getCleanedString } from '@canalplus/mycanal-commons';
import type { ApiV2ArticleInformation } from '@dce-front/hodor-types/api/v2/article/definitions';
import { type JSX } from 'react';

export type SchemaArticleProps = {
  informations: ApiV2ArticleInformation;
};

export const SchemaArticle = ({
  informations,
}: SchemaArticleProps): JSX.Element => {
  const { title, abstract, mainMedia, author, publishedAt, updatedAt } =
    informations;

  const formatPublishedAt = publishedAt
    ? new Date(publishedAt).toISOString()
    : '';

  const formatUpdatedAt = updatedAt ? new Date(updatedAt).toISOString() : '';

  const headline = title ? getCleanedString(title) : '';
  const description = abstract ? getCleanedString(abstract) : null;

  const DATA = `{
    "@context": "http://schema.org/",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://google.com/article"
    },
    "headline": "${headline}",
    "image": "${mainMedia && mainMedia.URLImage}",
    "datePublished": "${formatPublishedAt}", 
    "dateModified": "${formatUpdatedAt}",
    ${
      author
        ? `"author": {
      "@type": "Person",
      "name": "${author.name}"
    },`
        : ''
    }
    "publisher": {
      "@type": "Organization",
      "name": "myCANAL",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.canalplus.com/widetile.png"
      }
    }${description ? `,\n "description": "${description}"` : ''}
  }`;

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: DATA }}
    />
  );
};
